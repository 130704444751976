<template>
  <v-container>
    <perseu-card title="Homologar Terapeuta">
      <v-flex slot="content">
        <filters-panel class="mb-3">
          <v-row>
            <v-col cols="12" sm="4" md="6">
              <v-autocomplete
                v-model="query.status"
                :items="allItemns"
                outlined
                chips
                small-chips
                multiple
              >
              </v-autocomplete>
            </v-col>
            <v-col md="6" cols="12">
              <v-text-field
                hide-details
                v-model="query.therapistName"
                filled
                label="Terapeuta"
              />
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-btn color="secondary" @click="list"> Buscar</v-btn>
          </v-row>
        </filters-panel>
        <v-data-table
          :items="homologations"
          :headers="headers"
          class="elevation-1"
        >
          <template v-slot:item.status="{ item }">
            <v-chip :color="getColor(item.status)" dark>
              {{ item.status }}
            </v-chip>
          </template>
          <template #item.actions="props">
            <v-btn small icon @click="edit(props.item)" color="secondary">
              <v-icon small>edit</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-flex>
    </perseu-card>
  </v-container>
</template>

<script>
import { find, allStatus } from "@/services/therapists-homologation-service";
import { pagesNames } from "@/router/pages-names";

export default {
  data: () => ({
    headers: [
      { text: "Nome do terapeuta", value: "therapistName" },
      { text: "Status", value: "status" },
      { text: "Cadastrado em", value: "createdAt" },
      { text: "Atualizado em", value: "updatedAt" },
      {
        text: "Ações",
        value: "actions",
        sortable: false,
        align: "start",
        width: 200,
      },
    ],
    query: {
      status: [],
      therapistName: "",
    },
    allItemns: [],
    homologations: [],
  }),
  created() {
    this.load();
  },
  methods: {
    async list() {
      try {
        this.$store.dispatch("loading/openDialog");
        let response = await find(this.getFilter());
        this.homologations = response.data;
      } catch (e) {
        this.$errorHandler(e);
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },
    async load() {
      try {
        this.$store.dispatch("loading/openDialog");
        const status = await allStatus();
        this.allItemns = status.data;
        let response = await find(this.getFilter());
        this.homologations = response.data;
      } catch (e) {
        this.$errorHandler(e);
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },
    getFilter() {
      let filter = {};
      filter.status = this.query.status;
      filter.therapistName = this.query.therapistName;
      return filter;
    },
    async edit({ id }) {
      this.$router.push({
        name: pagesNames.HOMOLOGATE_THERAPISTS_FORM,
        params: { id },
      });
    },
    getColor(status) {
      if (status === "Recusado") return "red";

      if (status === "Aceito") return "green";

      return "orange";
    },
  },
};
</script>
