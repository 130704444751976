import api from "./api-service";
import { stringify } from "querystring";

const baseUrl = "therapists-homologations";

export const getData = () => api().get(`${baseUrl}/data`);

export const find = (filter) => api().post(`${baseUrl}/find`, filter);

export const allStatus = () => api().get(`${baseUrl}/allStatus`);

export const getOne = (id) => api().get(`${baseUrl}/getOne/${id}`);

export const reject = (data) => api().post(`${baseUrl}/reject`, data);

export const approve = (data) => api().post(`${baseUrl}/approve`, data);
